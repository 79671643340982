import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { hasLetter, hasNumber, isCnpjPatternValid, isCnsValid, isCpfPatternValid, isDateValid, isDDValid, isEmailValid, isHeightValid, isNameValid, isPasswordValid, isPhoneValid, isSocialSecurityNumberValid, isWeightValid, isZipCodeValid, maxDate, } from './regex-validator';
export class Validators {
    static MaxLength(length = 50, message) {
        return {
            error: {
                name: 'MaxLength',
                message: message !== null && message !== void 0 ? message : `O número máximo de caracteres permitidos é ${length}`,
            },
            validationFn: (value) => {
                return !value || value.toLocaleString().length <= length;
            },
        };
    }
    static LettersOnly(acceptSpace, message) {
        return {
            error: {
                name: 'LettersOnly',
                message,
            },
            validationFn: (value) => {
                return value === null || value === void 0 ? void 0 : value.match(acceptSpace ? /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\s]*$/ : /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]*$/);
            },
        };
    }
    static MinLength(length, message) {
        return {
            error: {
                name: 'MinLength',
                message,
            },
            validationFn: (value) => {
                return (value === null || value === void 0 ? void 0 : value.toLocaleString().length) >= length;
            },
        };
    }
    static HeightRegex(message) {
        return {
            error: {
                name: 'HeightRegex',
                message,
            },
            validationFn: (value) => {
                return isHeightValid(value);
            },
        };
    }
    static WeightRegex(message) {
        return {
            error: {
                name: 'WeightRegex',
                message,
            },
            validationFn: (value) => {
                return isWeightValid(value);
            },
        };
    }
    static Required(message = 'Campo obrigatório') {
        return {
            error: {
                name: 'Required',
                message: message,
            },
            validationFn: (value) => {
                if (Array.isArray(value) && !(value === null || value === void 0 ? void 0 : value.length)) {
                    return false;
                }
                return (value && Object.keys(value).length > 0) || (value && !isNaN(value)) || value instanceof Date;
            },
        };
    }
    static EmailRegex(message = 'E-mail inválido') {
        return {
            error: {
                name: 'EmailRegex',
                message,
            },
            validationFn: (value) => {
                return isEmailValid(value);
            },
        };
    }
    static CpfPatternRegex(message) {
        return {
            error: {
                name: 'Cpf pattern regex',
                message,
            },
            validationFn: (value) => {
                return isCpfPatternValid(value);
            },
        };
    }
    static CnpjPatternRegex(message) {
        return {
            error: {
                name: 'Cnpj pattern regex',
                message,
            },
            validationFn: (value) => {
                return isCnpjPatternValid(value);
            },
        };
    }
    static ZipCodeRegex(message = 'CEP inválido') {
        return {
            error: {
                name: 'Zip code regex',
                message,
            },
            validationFn: (value) => {
                return isZipCodeValid(value);
            },
        };
    }
    static PhoneRegex(message = 'Telefone inválido') {
        return {
            error: {
                name: 'PhoneRegex',
                message,
            },
            validationFn: (value) => {
                return isPhoneValid(value);
            },
        };
    }
    static IsPossiblePhoneNumber(message = 'Telefone inválido') {
        return {
            error: {
                name: 'IsPossiblePhoneNumber',
                message,
            },
            validationFn: (value) => {
                return !!value && isPossiblePhoneNumber(value);
            },
        };
    }
    static DDRegex(message) {
        return {
            error: {
                name: 'DDRegex',
                message,
            },
            validationFn: (value) => {
                return isDDValid(value);
            },
        };
    }
    static PasswordRegex(message = 'Senha inválida') {
        return {
            error: {
                name: 'PasswordRegex',
                message,
            },
            validationFn: (value) => {
                return isPasswordValid(value);
            },
        };
    }
    static FullNameRegex(message) {
        return {
            error: {
                name: 'FullNameRegex',
                message,
            },
            validationFn: (value) => {
                return isNameValid(value);
            },
        };
    }
    static DateRegex(message = 'Data inválida') {
        return {
            error: {
                name: 'DateRegex',
                message,
            },
            validationFn: (value) => {
                if (!(value === null || value === void 0 ? void 0 : value.length)) {
                    return true;
                }
                return isDateValid(value);
            },
        };
    }
    static MaxDate(max, message) {
        return {
            error: {
                name: 'MaxDate',
                message,
            },
            validationFn: (value) => {
                return !value || maxDate(max, value);
            },
        };
    }
    static IsNotEqualToField(valueToCompare, message) {
        return {
            error: {
                name: 'IsNotEqualToField',
                message,
            },
            validationFn: (value) => {
                return value === valueToCompare;
            },
        };
    }
    static AtLeastOneOf(valueToCompare, message) {
        return {
            error: {
                name: 'AtLeastOneOf',
                message,
            },
            validationFn: (value) => {
                return !!value || !!valueToCompare;
            },
        };
    }
    // Validates Brazilian social security number
    static SocialSecurityNumberRegex(message) {
        return {
            error: {
                name: 'SocialSecurityNumberRegex',
                message,
            },
            validationFn: (value) => {
                return isSocialSecurityNumberValid(value);
            },
        };
    }
    static CnsRegex(message) {
        return {
            error: {
                name: 'CnsRegex',
                message,
            },
            validationFn: (value) => {
                return !value || isCnsValid(value);
            },
        };
    }
    static HasNumber(errorMessage) {
        return {
            error: {
                name: 'HasNumber',
                message: errorMessage,
            },
            validationFn: (value) => {
                if (typeof value === 'string') {
                    return hasNumber(value);
                }
                return false;
            },
        };
    }
    static HasLetter(errorMessage) {
        return {
            error: {
                name: 'HasLetter',
                message: errorMessage,
            },
            validationFn: (value) => {
                if (typeof value === 'string') {
                    return hasLetter(value);
                }
                return false;
            },
        };
    }
}
