import { compareAsc, format, parse, parseISO } from 'date-fns';
import { DATE_FORMATS } from './constant.utils';
export const formatDate = (inputDate, outputFormat = DATE_FORMATS.DATE_WITH_MONTH, ignoreTimeZone) => {
    if (!inputDate) {
        return null;
    }
    let date = inputDate;
    if (ignoreTimeZone) {
        date = date.replace('Z', '');
    }
    const convertedDate = parseISO(date);
    return format(convertedDate, outputFormat);
};
export const formatStringToDate = (inputDateString, inputFormat = DATE_FORMATS.FULL_DATE) => {
    if (!inputDateString) {
        return null;
    }
    return parse(inputDateString, inputFormat, new Date());
};
export const convertToMinutes = (hours = 0, minutes = 0) => {
    return hours * 60 + minutes;
};
export const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return { hours, minutes: remainingMinutes };
};
export const sortDatesAscending = (date1, date2) => {
    if (!date1 && !date2) {
        return 0;
    }
    if (!date1) {
        return 1;
    }
    if (!date2) {
        return -1;
    }
    return compareAsc(parseISO(date1), parseISO(date2));
};
