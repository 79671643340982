export const DEFAULT_DEBOUNCE_TIME_IN_MS = 500;
export const ONE_SECONDE_IN_MS = 1000;
export const DEFAULT_PAGE_LIMIT = 15;
export const HOUR_IN_MINUTES = 60;
export const DAY_IN_HOURS = 24;
export const states = [
    { initials: 'AC', name: 'Acre - AC' },
    { initials: 'AL', name: 'Alagoas - AL' },
    { initials: 'AM', name: 'Amazonas - AM' },
    { initials: 'AP', name: 'Amapá - AP' },
    { initials: 'BA', name: 'Bahia - BA' },
    { initials: 'CE', name: 'Ceará - CE' },
    { initials: 'DF', name: 'Distrito Federal - DF' },
    { initials: 'ES', name: 'Espírito Santo - ES' },
    { initials: 'GO', name: 'Goiás - GO' },
    { initials: 'MA', name: 'Maranhão - MA' },
    { initials: 'MG', name: 'Minas Gerais - MG' },
    { initials: 'MS', name: 'Mato Grosso do Sul - MS' },
    { initials: 'MT', name: 'Mato Grosso - MT' },
    { initials: 'PA', name: 'Pará - PA' },
    { initials: 'PB', name: 'Paraíba - PB' },
    { initials: 'PE', name: 'Pernambuco - PE' },
    { initials: 'PI', name: 'Piauí - PI' },
    { initials: 'PR', name: 'Paraná - PR' },
    { initials: 'RJ', name: 'Rio de Janeiro - RJ' },
    { initials: 'RN', name: 'Rio Grande do Norte - RN' },
    { initials: 'RO', name: 'Rondônia - RO' },
    { initials: 'RR', name: 'Roraima - RR' },
    { initials: 'RS', name: 'Rio Grande do Sul - RS' },
    { initials: 'SC', name: 'Santa Catarina - SC' },
    { initials: 'SE', name: 'Sergipe - SE' },
    { initials: 'SP', name: 'São Paulo - SP' },
    { initials: 'TO', name: 'Tocantins - TO' },
];
export const DATE_FORMATS = {
    FULL_DATE: 'dd/MM/yyyy',
    DATE_WITH_MONTH: 'dd/MM',
    FULL_DATE_WITH_TIME: 'dd/MM/yyyy HH:mm',
};
export const TIME_FORMAT = 'HH:mm';
export const AVATAR_ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];
export const AVATAR_ALLOWED_SIZE_IN_BYTES = 1024 * 1024 * 2; // 2 MB
