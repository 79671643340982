import React, { useCallback } from 'react';
import { ContentWrapper } from '@app/components/atm.content-wrapper/content-wrapper.component';
import { SearchBar } from '@app/components/atm.search-bar';
import { KanbanLane } from '@app/components/mol.kanban-lane';
import { KanbanShimmer } from '@app/components/mol.kanban-shimmer/kanban-shimmer.component';
import { ErrorPlaceholder } from '@app/components/mol.placeholder/error-placeholder.component';
import { HomeSidebar } from '@app/core/route/home-sidebar-element.component';
import { TeamType } from '@app/data/graphql/base-schema';
import { useGetBookingsKanban } from '@app/domain/booking/get-bookings-kanban.use-case';
import { H1, H4 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Outlet, useNavigate } from 'react-router';
import { AlertBadge } from './components/alert-badge.component';
import { HomeKanbanCard } from './components/home-kanban-card.component';
import { KanbanWrapper } from './components/kanban-wrapper.component';
import { HomeRoutes } from './home.routes';
import { homeStrings } from './home.strings';
import { Button } from '@atomic/atm.button';
import { AppIcon } from '@app/components/atm.app-icon';
const strings = homeStrings;
const HomePage = () => {
    const [filters, setFilters] = React.useState({});
    const navigate = useNavigate();
    const { data, loading, error, refetch } = useGetBookingsKanban({
        filters,
    });
    const handleFilterChange = useCallback((newFilters) => {
        setFilters((prevFilters) => (Object.assign(Object.assign({}, prevFilters), newFilters)));
    }, []);
    const handleCardClick = (booking) => {
        navigate(`${HomeRoutes.Booking}/${booking.id}`);
    };
    const handleSearchTermChange = (event) => {
        const query = event.currentTarget.value;
        setFilters((prevFilters) => (Object.assign(Object.assign({}, prevFilters), { query })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HomeSidebar, { onFilterChange: handleFilterChange },
            React.createElement(ContentWrapper, null,
                React.createElement(Row, { justifyContent: 'space-between' },
                    React.createElement(H1, { noMargin: true }, strings.bookings),
                    React.createElement(Button, { variant: 'secondary', loading: loading, onClick: refetch },
                        React.createElement(AppIcon.Refresh, { size: 'large', variant: 'grayDark' }),
                        strings.refresh)),
                React.createElement(Spacer, { "$height": Spacing.XLarge }),
                React.createElement(SearchBar, { onChange: handleSearchTermChange, placeholder: strings.searchBarPlaceholder }),
                React.createElement(Spacer, { "$height": Spacing.Large }),
                React.createElement(Row, { gap: Spacing.Medium },
                    React.createElement(AlertBadge, { fullVersion: true, alertType: TeamType.Operational }),
                    React.createElement(AlertBadge, { fullVersion: true, alertType: TeamType.Service }))),
            React.createElement(Spacer, { "$height": Spacing.XLarge }),
            React.createElement(KanbanWrapper, null,
                React.createElement(LoadingState, { data: !!(data === null || data === void 0 ? void 0 : data.length), loading: loading, error: !!error },
                    React.createElement(LoadingState.Shimmer, null,
                        React.createElement(KanbanShimmer, null)),
                    React.createElement(LoadingState.Error, null,
                        React.createElement(ErrorPlaceholder, { type: 'notFound', description: strings.tryAgain, title: strings.somethingWentWrong })),
                    React.createElement(Row, { gap: Spacing.Large }, data === null || data === void 0 ? void 0 :
                        data.filter((item) => item.step.active).map((stepData, index) => {
                            var _a;
                            return (React.createElement(Column, { key: stepData.step.id },
                                React.createElement(KanbanLane, { rowNumber: index },
                                    React.createElement(H4, { noMargin: true, variant: 'black' },
                                        stepData.step.name,
                                        strings.newBookingsCount(stepData.bookings.length)),
                                    React.createElement(Spacer, { "$height": Spacing.Large }),
                                    React.createElement(Column, { gap: Spacing.Small }, (_a = stepData.bookings) === null || _a === void 0 ? void 0 : _a.map((booking) => {
                                        return (React.createElement(HomeKanbanCard, { onClick: () => handleCardClick(booking), key: booking.id, booking: booking }));
                                    })))));
                        }),
                        React.createElement(Spacer, { "$height": Spacing.XXLarge })),
                    React.createElement(Spacer, { "$height": Spacing.XXLarge })))),
        React.createElement(Outlet, null)));
};
export default HomePage;
