import { AppIcon } from '@app/components/atm.app-icon';
import { AvatarImage } from '@app/components/atm.avatar/avatar.component';
import { BookingValidationType } from '@app/data/graphql';
import { useAddBookingValidation } from '@app/domain/booking/add-booking-validation.use-case';
import { CheckboxField } from '@atomic/atm.checkbox';
import { TextAreaField } from '@atomic/atm.text-field';
import { Body, H4 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import React from 'react';
import { homeStrings } from '../home.strings';
import { AttachmentsSection } from './attachments-section.component';
import { BookingGeneralDataTable } from './booking-general-data.component';
import { GuestsTable } from './guests-table.component';
import { PaymentsTable } from './payments-table.component';
import { formatDate } from '@app/utils/time.utils';
const strings = homeStrings.drawer;
export var Tabs;
(function (Tabs) {
    Tabs[Tabs["BookingData"] = 0] = "BookingData";
    Tabs[Tabs["Tasks"] = 1] = "Tasks";
})(Tabs || (Tabs = {}));
export const BookingDetailsTab = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { addBookingValidation } = useAddBookingValidation();
    const handleValidationCheck = React.useCallback((validation) => {
        if (confirm(strings.confirmValidationChange)) {
            addBookingValidation({
                input: {
                    bookingId: props.booking.id,
                    type: validation,
                },
            });
        }
    }, [props.booking.id, addBookingValidation]);
    const hasGuestWithSyncIssue = (_b = (_a = props.booking) === null || _a === void 0 ? void 0 : _a.guests) === null || _b === void 0 ? void 0 : _b.some((guest) => guest.hadProblemOnSyncing);
    return (React.createElement(React.Fragment, null,
        React.createElement(BookingGeneralDataTable, { booking: props.booking }),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(H4, { variant: 'black' }, strings.validations),
        React.createElement(Row, null,
            React.createElement(Column, { flex: 1 },
                React.createElement(Body, { bold: true }, strings.validationsTable.type)),
            React.createElement(Column, { flex: 1 },
                React.createElement(Body, { bold: true }, strings.validationsTable.controller)),
            React.createElement(Column, { flex: 1 },
                React.createElement(Body, { bold: true }, strings.validationsTable.doneAt))),
        React.createElement(Spacer, { "$height": Spacing.Medium }), (_c = Object.values(BookingValidationType)) === null || _c === void 0 ? void 0 :
        _c.map((validationType) => {
            var _a, _b, _c, _d;
            const completedValidation = (_b = (_a = props.booking) === null || _a === void 0 ? void 0 : _a.completedValidations) === null || _b === void 0 ? void 0 : _b.find((validation) => validation.type === validationType);
            const isChecked = !!completedValidation;
            return (React.createElement(React.Fragment, { key: validationType },
                React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                    React.createElement(Column, { flex: 1 },
                        React.createElement(CheckboxField, { name: `${validationType}`, id: validationType, checked: isChecked, disabled: isChecked, strikethrough: isChecked, variant: 'neutral', size: 'medium', onValueChange: () => handleValidationCheck(validationType) }, strings.validationsTypes[validationType])),
                    React.createElement(Column, { flex: 1 }, !!completedValidation && (React.createElement(Row, { alignItems: 'center', gap: Spacing.Small },
                        React.createElement(AvatarImage, { size: 'xLarge', src: (_c = completedValidation === null || completedValidation === void 0 ? void 0 : completedValidation.author) === null || _c === void 0 ? void 0 : _c.avatarUrl }),
                        React.createElement(Body, { variant: 'neutral' }, (_d = completedValidation === null || completedValidation === void 0 ? void 0 : completedValidation.author) === null || _d === void 0 ? void 0 : _d.name)))),
                    React.createElement(Column, { flex: 1 }, !!(completedValidation === null || completedValidation === void 0 ? void 0 : completedValidation.completedAt) && (React.createElement(Row, { alignItems: 'center', gap: Spacing.XSmall },
                        React.createElement(AppIcon.Clock, { size: 'large', variant: 'grayXDark' }),
                        React.createElement(Body, { variant: 'neutral' }, formatDate(completedValidation === null || completedValidation === void 0 ? void 0 : completedValidation.completedAt, "dd.MM.yyyy 'às' HH:mm")))))),
                React.createElement(Spacer, { "$height": Spacing.Medium })));
        }),
        !!props.booking.guests && (React.createElement(React.Fragment, null,
            React.createElement(Row, { alignItems: 'center', gap: Spacing.Small },
                hasGuestWithSyncIssue && React.createElement(AppIcon.Warning, { variant: 'alertDark', size: 'large' }),
                React.createElement(H4, { variant: hasGuestWithSyncIssue ? 'alert' : 'black' }, strings.guests)),
            React.createElement(GuestsTable, { guests: props.booking.guests, onGuestEdit: props.onGuestEdit, bookingId: props.booking.id }))),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(AttachmentsSection, { bookingId: (_d = props.booking) === null || _d === void 0 ? void 0 : _d.id }),
        !!((_e = props.booking.guestBillings) === null || _e === void 0 ? void 0 : _e.length) && (React.createElement(React.Fragment, null,
            React.createElement(H4, { variant: 'black' }, strings.payments),
            React.createElement(PaymentsTable, { guestBillings: (_f = props.booking) === null || _f === void 0 ? void 0 : _f.guestBillings }))),
        !!((_g = props.booking) === null || _g === void 0 ? void 0 : _g.comment) && (React.createElement(React.Fragment, null,
            React.createElement(Spacer, { "$height": Spacing.Large }),
            React.createElement(H4, { variant: 'black' }, strings.comments),
            React.createElement(TextAreaField, { readOnly: true, value: props.booking.comment, expandToContentHeight: true })))));
};
