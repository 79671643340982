import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { BookingItemRecordStatus } from '@app/data/graphql';
import { BodySecondary } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row } from '@atomic/obj.grid';
import { homeStrings } from '../home.strings';
import { formatMinutesToHours } from '@app/utils/time.utils';
const strings = homeStrings.drawer;
export const BookingItemRecord = (props) => {
    const { name, deadline, status, shouldTriggerBeforeDeadlineReference, deadlineFriendlyDescription, completedAt } = props.bookingItemRecord;
    const getDeadlineText = () => {
        if (!deadline || !deadlineFriendlyDescription || shouldTriggerBeforeDeadlineReference === undefined) {
            return '-';
        }
        const deadlineTime = formatMinutesToHours(deadline);
        const hoursString = deadlineTime.hours > 0 ? strings.hours(deadlineTime.hours) : '';
        const minutesString = deadlineTime.minutes > 0 ? strings.minutes(deadlineTime.minutes) : '';
        return strings.deadlineText(hoursString, minutesString, deadlineFriendlyDescription, shouldTriggerBeforeDeadlineReference);
    };
    const renderCompletedAtColumn = () => {
        if (completedAt) {
            return React.createElement(BodySecondary, null, strings.completedAtText(completedAt));
        }
        return status === BookingItemRecordStatus.Overdue ? (React.createElement(AppIcon.Warning, { variant: 'warning', size: 'large' })) : (React.createElement(AppIcon.Clock, { variant: 'grayDark', size: 'large' }));
    };
    return (React.createElement(Row, { gap: Spacing.XXLarge },
        React.createElement(Column, { flex: 1 },
            React.createElement(BodySecondary, { variant: status === BookingItemRecordStatus.Overdue ? 'warning' : undefined, strikethrough: status === BookingItemRecordStatus.Completed }, name)),
        React.createElement(Column, { flex: 1 }, renderCompletedAtColumn()),
        React.createElement(Column, { flex: 1 },
            React.createElement(BodySecondary, { variant: status === BookingItemRecordStatus.Overdue ? 'warning' : undefined }, getDeadlineText()))));
};
