var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export const PageParams = 'page';
export const onlyDigits = (value) => {
    return value === null || value === void 0 ? void 0 : value.replace(/\D/g, '');
};
export const limitString = (str, limit = 25) => {
    return (str === null || str === void 0 ? void 0 : str.length) > limit ? `${str.substring(0, limit)}...` : str;
};
export const createFormattedAliasWithHotelName = (alias, hotelName) => {
    if (!alias) {
        return hotelName;
    }
    return `${alias} - ${hotelName}`;
};
export const formatCurrency = (value) => {
    return value === null || value === void 0 ? void 0 : value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
};
export const pluralize = (_a) => {
    var { count } = _a, options = __rest(_a, ["count"]);
    if (!count && options.empty) {
        return options.empty;
    }
    return count === 1 ? options.singular : options.plural;
};
export const addPlusToPhoneNumber = (phone) => {
    return phone === null || phone === void 0 ? void 0 : phone.replace(/^(\+)?(.*)$/, '+$2');
};
