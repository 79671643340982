import React from 'react';
import { addPlusToPhoneNumber, onlyDigits } from '@app/utils/string.utils';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { InputCaption } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Form, FormField, Validators } from '@atomic/obj.form';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { HotelsRoutes } from '../hotels.routes';
import { hotelStrings } from '../hotels.strings';
import { HotelAddressFormFields } from './hotel-address-form.component';
import { CheckboxField } from '@atomic/atm.checkbox';
import { PhoneInput } from '@atomic/atm.phone-field/phone-field.component';
const strings = hotelStrings.hotelForm;
var HotelFormFields;
(function (HotelFormFields) {
    HotelFormFields["idHIGS"] = "idHIGS";
    HotelFormFields["email"] = "email";
    HotelFormFields["name"] = "name";
    HotelFormFields["telephone"] = "telephone";
    HotelFormFields["alias"] = "alias";
    HotelFormFields["active"] = "active";
})(HotelFormFields || (HotelFormFields = {}));
export const HotelForm = (props) => {
    const { hotel } = props;
    const [email, setEmail] = React.useState(hotel === null || hotel === void 0 ? void 0 : hotel.email);
    const [telephone, setTelephone] = React.useState(hotel === null || hotel === void 0 ? void 0 : hotel.telephone);
    const handleSubmit = (formData) => {
        if (formData.hasError) {
            return;
        }
        const data = formData.data;
        if (hotel) {
            delete data.idHIGS;
        }
        props.onSubmit(Object.assign(Object.assign({}, data), { telephone: onlyDigits(data.telephone) || null, email: data.email || null, address: Object.assign(Object.assign({}, data.address), { postalCode: onlyDigits(data.address.postalCode) }) }));
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(Row, { gap: Spacing.XLarge },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { label: strings.idHIGS, name: HotelFormFields.idHIGS, initialValue: hotel === null || hotel === void 0 ? void 0 : hotel.idHIGS, validators: [Validators.Required()] },
                    React.createElement(TextField, { placeholder: strings.placeholder.idHIGS, disabled: !!hotel })),
                React.createElement(Spacer, { "$height": Spacing.XLarge }),
                React.createElement(FormField, { label: strings.name, name: HotelFormFields.name, initialValue: hotel === null || hotel === void 0 ? void 0 : hotel.name, validators: [Validators.Required(), Validators.MaxLength()] },
                    React.createElement(TextField, { placeholder: strings.placeholder.name })),
                React.createElement(Spacer, { "$height": Spacing.XLarge }),
                React.createElement(FormField, { label: strings.alias, name: HotelFormFields.alias, initialValue: hotel === null || hotel === void 0 ? void 0 : hotel.alias, validators: [Validators.Required()] },
                    React.createElement(TextField, { placeholder: strings.placeholder.alias }),
                    React.createElement(InputCaption, null, strings.aliasDescription)),
                React.createElement(Spacer, { "$height": Spacing.XLarge }),
                React.createElement(FormField, { label: strings.email, name: HotelFormFields.email, initialValue: email, onValueChange: (value) => setEmail(value), validators: [email && Validators.EmailRegex()].filter(Boolean) },
                    React.createElement(TextField, { placeholder: strings.placeholder.email })),
                React.createElement(Spacer, { "$height": Spacing.XLarge }),
                React.createElement(Form.Field, { name: HotelFormFields.telephone, initialValue: addPlusToPhoneNumber(telephone), onValueChange: (value) => setTelephone(value), validators: [telephone && Validators.IsPossiblePhoneNumber()].filter(Boolean), label: strings.telephone },
                    React.createElement(PhoneInput, null),
                    React.createElement(InputCaption, null, strings.contactViaChat)),
                React.createElement(Spacer, { "$height": Spacing.XLarge }),
                React.createElement(FormField, { name: HotelFormFields.active, initialValue: (hotel === null || hotel === void 0 ? void 0 : hotel.active) ? [HotelFormFields.active] : [] },
                    React.createElement(CheckboxField, { id: HotelFormFields.active }, strings.active)),
                React.createElement(Spacer, { "$height": Spacing.XLarge })),
            React.createElement(Column, { flex: 1 },
                React.createElement(HotelAddressFormFields, { address: hotel === null || hotel === void 0 ? void 0 : hotel.address }))),
        React.createElement(Spacer, { "$height": Spacing.XLarge }),
        React.createElement(Row, { justifyContent: 'flex-end', gap: Spacing.Medium },
            React.createElement(Button, { variant: 'secondary', to: HotelsRoutes.Base }, strings.cancel),
            React.createElement(Button, { variant: 'callToAction', type: 'submit', loading: props.loading }, strings.publish))));
};
