import { gql } from '@apollo/client';
import { SimpleBookingFragmentDoc } from './simple-booking.fragment';
export const BookingsDocument = gql `
  query bookings($input: BookingsInput) {
    bookings(input: $input) {
      ...SimpleBooking
    }
  }
  ${SimpleBookingFragmentDoc}
`;
