import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { DATE_FORMATS } from '@app/utils/constant.utils';
import { formatCurrency } from '@app/utils/string.utils';
import { DT, DD, H4 } from '@atomic/atm.typography';
import { ClickableIcon } from '@atomic/obj.clickable';
import { BookingTR, BookingTD } from './booking-data-table.component.style';
import { Row } from '@atomic/obj.grid';
import { homeStrings } from '../home.strings';
import { PasswordField } from '@atomic/mol.password-field';
import { Spacing } from '@atomic/obj.constants';
import { Button } from '@atomic/atm.button';
import { useUpdateBooking } from '@app/domain/booking/update-booking.use-case';
import { handleKeyPress } from '@app/core/browser';
import { formatDate } from '@app/utils/time.utils';
const strings = homeStrings.drawer;
export const BookingGeneralDataTable = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const [hidePassword, setHidePassword] = React.useState(true);
    const [isEditingPassword, setIsEditingPassword] = React.useState(false);
    const [password, setPassword] = React.useState((_a = props.booking) === null || _a === void 0 ? void 0 : _a.roomPassword);
    const initialPassword = React.useRef((_b = props.booking) === null || _b === void 0 ? void 0 : _b.roomPassword);
    const { updateBooking, loading } = useUpdateBooking({
        onCompleted: (result) => {
            var _a, _b;
            initialPassword.current = (_a = result.updateBooking) === null || _a === void 0 ? void 0 : _a.roomPassword;
            setPassword((_b = result.updateBooking) === null || _b === void 0 ? void 0 : _b.roomPassword);
            setIsEditingPassword(false);
        },
    });
    const handlePasswordUpdate = () => {
        var _a;
        updateBooking({ id: (_a = props.booking) === null || _a === void 0 ? void 0 : _a.id, roomPassword: password });
    };
    const handleClosePasswordEdition = () => {
        setPassword(initialPassword.current);
        setIsEditingPassword(false);
    };
    const Icon = hidePassword ? AppIcon.EyeOpened : AppIcon.EyeClosed;
    const renderPasswordField = () => {
        if (isEditingPassword) {
            return (React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                React.createElement(PasswordField, { autoComplete: 'new-password', isSecure: hidePassword, value: password, onValueChange: (value) => setPassword(value), onSecureChange: setHidePassword, onKeyDown: (event) => handleKeyPress(event, 'Enter', handlePasswordUpdate) }),
                React.createElement(Button, { variant: 'secondary', onClick: handleClosePasswordEdition, iconButton: true, circular: true },
                    React.createElement(AppIcon.Close, { size: 'large', variant: 'secondary' })),
                React.createElement(Button, { variant: 'callToAction', loading: loading, onClick: handlePasswordUpdate, iconButton: true, circular: true },
                    React.createElement(AppIcon.DoneCircular, { size: 'large', variant: 'grayXDark' }))));
        }
        if (!(password === null || password === void 0 ? void 0 : password.length)) {
            return (React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
                React.createElement(DD, null, "-"),
                React.createElement(ClickableIcon, { onClick: () => setIsEditingPassword(true) },
                    React.createElement(AppIcon.Edit, { size: 'large', variant: 'secondary' }))));
        }
        return (React.createElement(Row, { gap: Spacing.Small, alignItems: 'center' },
            hidePassword ? (React.createElement(DD, null, Array(password === null || password === void 0 ? void 0 : password.length)
                .fill('*')
                .map((item, index) => (React.createElement("span", { key: index }, item))))) : (React.createElement(DD, null, password)),
            React.createElement(ClickableIcon, { onClick: () => setHidePassword(!hidePassword) },
                React.createElement(Icon, { size: 'large', variant: 'secondary' })),
            React.createElement(ClickableIcon, { onClick: () => setIsEditingPassword(true) },
                React.createElement(AppIcon.Edit, { size: 'large', variant: 'secondary' }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(H4, { variant: 'black' }, strings.generalData),
        React.createElement(Row, { justifyContent: 'space-between', alignItems: 'flex-start' },
            React.createElement("table", null,
                React.createElement("tbody", null,
                    React.createElement(BookingTR, null,
                        React.createElement(BookingTD, null,
                            React.createElement(DT, null, strings.createDate)),
                        React.createElement(BookingTD, null,
                            React.createElement(DD, null, formatDate((_c = props.booking) === null || _c === void 0 ? void 0 : _c.createdAt, DATE_FORMATS.FULL_DATE)))),
                    React.createElement(BookingTR, null,
                        React.createElement(BookingTD, null,
                            React.createElement(DT, null, strings.guestAmount)),
                        React.createElement(BookingTD, null,
                            React.createElement(DD, null, (_d = props.booking) === null || _d === void 0 ? void 0 : _d.guestCount))),
                    React.createElement(BookingTR, null,
                        React.createElement(BookingTD, null,
                            React.createElement(DT, null, strings.childAmount)),
                        React.createElement(BookingTD, null,
                            React.createElement(DD, null, (_e = props === null || props === void 0 ? void 0 : props.booking) === null || _e === void 0 ? void 0 : _e.childrenCount))),
                    React.createElement(BookingTR, null,
                        React.createElement(BookingTD, null,
                            React.createElement(DT, null, strings.roomNumber)),
                        React.createElement(BookingTD, null,
                            React.createElement(DD, null, (_g = (_f = props.booking) === null || _f === void 0 ? void 0 : _f.roomNumber) !== null && _g !== void 0 ? _g : '-'))),
                    React.createElement(BookingTR, null,
                        React.createElement(BookingTD, null,
                            React.createElement(DT, null, strings.roomPassword)),
                        React.createElement(BookingTD, null,
                            React.createElement(Row, null, renderPasswordField()))))),
            React.createElement("table", null,
                React.createElement("tbody", null,
                    React.createElement(BookingTR, null,
                        React.createElement(BookingTD, null,
                            React.createElement(DT, null, strings.totalValue)),
                        React.createElement(BookingTD, null,
                            React.createElement(DD, null, formatCurrency((_h = props === null || props === void 0 ? void 0 : props.booking) === null || _h === void 0 ? void 0 : _h.totalAmountBeforeTax)))),
                    React.createElement(BookingTR, null,
                        React.createElement(BookingTD, null,
                            React.createElement(DT, null, strings.effectiveCheckIn)),
                        React.createElement(BookingTD, null,
                            React.createElement(DD, null, ((_j = props.booking) === null || _j === void 0 ? void 0 : _j.effectiveCheckIn) ? strings.dateAtTime(props.booking.effectiveCheckIn) : ''))),
                    React.createElement(BookingTR, null,
                        React.createElement(BookingTD, null,
                            React.createElement(DT, null, strings.effectiveCheckOut)),
                        React.createElement(BookingTD, null,
                            React.createElement(DD, null, ((_k = props.booking) === null || _k === void 0 ? void 0 : _k.effectiveCheckOut) ? strings.dateAtTime(props.booking.effectiveCheckOut) : ''))))))));
};
