import React from 'react';
import { GuestFormFields } from '@app/model/booking/booking-guest.model';
import { TextField } from '@atomic/atm.text-field';
import { Spacing } from '@atomic/obj.constants';
import { FormField, Validators } from '@atomic/obj.form';
import { Column, Row, Spacer } from '@atomic/obj.grid';
import { homeStrings } from '../home.strings';
import { DATE_FORMATS } from '@app/utils/constant.utils';
import { formatDate } from '@app/utils/time.utils';
const strings = homeStrings.guestForm;
export const GuestGeneralDataForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: GuestFormFields.name, initialValue: (_a = props.guest) === null || _a === void 0 ? void 0 : _a.name, label: strings.name },
                    React.createElement(TextField, null))),
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: GuestFormFields.lastName, initialValue: (_b = props.guest) === null || _b === void 0 ? void 0 : _b.lastName, label: strings.lastName },
                    React.createElement(TextField, null)))),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `document.${GuestFormFields.cpf}`, initialValue: (_d = (_c = props.guest) === null || _c === void 0 ? void 0 : _c.document) === null || _d === void 0 ? void 0 : _d.cpf, label: strings.document.cpf },
                    React.createElement(TextField, { variant: 'cpf' })))),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(Row, { gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(FormField, { name: `document.${GuestFormFields.passport}`, initialValue: (_f = (_e = props.guest) === null || _e === void 0 ? void 0 : _e.document) === null || _f === void 0 ? void 0 : _f.passport, label: strings.document.passport },
                    React.createElement(TextField, null)))),
        React.createElement(Spacer, { "$height": Spacing.Medium }),
        React.createElement(FormField, { name: GuestFormFields.birthDate, initialValue: formatDate((_g = props.guest) === null || _g === void 0 ? void 0 : _g.birthDate, DATE_FORMATS.FULL_DATE, true), label: strings.birthDate, validators: [Validators.DateRegex()] },
            React.createElement(TextField, { variant: 'datetime', options: { format: 'DD/MM/YYYY' } }))));
};
